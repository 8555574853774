

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function SmileFaq({setIsModalOpen}) {
    return (
      <>
      <Separator />
    
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
            <Row className="justify-content-center pb-5">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about <span>Smile Lines</span></Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What does the treatment involve? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The treatment is carried out by injecting dermal fillers strategically to the smile lines,
                        delivering results that are suitable for the patient. Treatment results are instant and the
                        treatment time typically lasts between 10-15 minutes.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Does the treatment hurt?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Numbing cream is applied to the area to be injected and only when it is numbed, the treatment is
                        done, making your experience as comforting as possible. Based on all our previous patients, the
                        pain was mild and some patients felt no pain at all.


                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What happens on the day? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We start the appointment with a thorough consultation to understand your requirements and
                        suitability. Once we have a full understanding and you’re happy to proceed, we start the
                        treatment, which takes approximately 10-15 minutes.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How long is recovery? </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No recovery time needed. You can get straight back to your daily routine, without taking time
                        off work.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>


                </Accordion>
              </Col>

              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Why choose Dermamina </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        We are renowned for providing natural looking yet life changing results, and being completely
                        honest with our patients. If we feel a treatment is not suitable for you, we will not recommend
                        for you to go ahead with it.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Col>
              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What if I change my mind after the consultation?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the case of changing your mind after the consultation, you will be charged £20 for the
                        consultation and the remaining will be refunded or can be used towards another treatment.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


              <Col
                lg="12"
                md="6"
                className="mb-4"
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="false"
                data-aos-delay="50"
              >
                <Accordion allowZeroExpanded>

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What if I am unhappy with the result?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Unlike a surgical treatment, this procedure is 100% reversible. This is done by simply
                        dissolving the filler, allowing the smile line to go back to its original form. The consultation
                        is designed in order to understand patients concerns, expectations and suitability. We are proud
                        to say that we have always delivered bespoke treatment, leaving all our patients fully satisfied
                        with the result.

                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </ Accordion>
              </ Col>


            </Row>


            <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active"
            >
              <Bookbutton>
                Book Appointment
              </Bookbutton>
            </div>


          </Container>
        </Section>


        <SeparatorEnd />
      </>

    );
}








